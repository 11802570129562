import request from '@/utils/request';

export async function queryRoleAndPerson(params?: { [key: string]: any }): Promise<RoleResponse> {
  return request.post('/permission/query_role_and_person_list', params);
}

export async function removeRole(params: Record<string, any>) {
  return request.post('/permission/delete_role', params);
}
export async function recoverRole(params: Record<string, any>) {
  return request.post('/permission/recover_role', params);
}

export async function addRole(params: Record<string, any>) {
  return request.post('/permission/create_role', params);
}

export async function updateRole(params: Record<string, any>) {
  return request.post('/permission/update_role', params);
}

interface RuleItem {
  key: string | number;
  callNo: number;
  avatar: string;
  desc: string;
  disabled: false;
  href: string;
  name: string;
  owner: string;
  progress: number;
  status: number;
  updatedAt: string;
  createdAt: string;
}

interface RoleResponse {
  current: number;
  data: RuleItem[];
  pageSize: string;
  success: boolean;
  total: number;
}
