<template>
  <page-container3>
    <div class="ant-pro-table">
      <div class="ant-pro-table-search">
        <search-render
          ref="config_render"
          :form="config"
          :model="config.model"
          :options="config_options"
          :validateInfos="validateInfos"
          @search="search"
        />
      </div>
      <a-card :body-style="{ padding: 0 }" ref="elRef">
        <div class="ant-pro-table-list-toolbar">
          <div class="ant-pro-table-list-toolbar-container">
            <div class="ant-pro-table-list-toolbar-left">
              <div class="ant-pro-table-list-toolbar-title">{{ t('权限管理') }}</div>
            </div>
            <div class="ant-pro-table-list-toolbar-right">
              <a-space align="center">
                <a-button type="primary" size="small" @click="() => handleCreatEdit()">
                  <plus-outlined />
                  {{ t('新建角色') }}
                </a-button>
                <div class="ant-pro-table-list-toolbar-setting-item">
                  <a-tooltip :title="t('表格斑马纹')">
                    <a-switch
                      :checked-children="t('开')"
                      :un-checked-children="t('关')"
                      v-model:checked="state.stripe"
                    />
                  </a-tooltip>
                </div>
              </a-space>
              <div class="ant-pro-table-list-toolbar-divider">
                <a-divider type="vertical" />
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('刷新')">
                  <reload-outlined @click="handleTableChange" />
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('密度')">
                  <a-dropdown :trigger="['click']" placement="bottomRight">
                    <column-height-outlined />
                    <template #overlay>
                      <a-menu
                        style="width: 80px"
                        :selected-keys="[state.tableSize]"
                        @click="
                          ({ key }) => {
                            state.tableSize = key;
                          }
                        "
                      >
                        <a-menu-item key="default">
                          <a href="javascript:">{{ t('默认') }}</a>
                        </a-menu-item>
                        <a-menu-item key="middle">
                          <a href="javascript:">{{ t('中等') }}</a>
                        </a-menu-item>
                        <a-menu-item key="small">
                          <a href="javascript:">{{ t('紧凑') }}</a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-popover
                  placement="bottomRight"
                  arrowPointAtCenter
                  trigger="click"
                  overlayClassName="ant-pro-table-column-setting-overlay"
                >
                  <template #title>
                    <div class="ant-pro-table-column-setting-title">
                      <a-checkbox
                        v-model:checked="columnState.checkAll"
                        :indeterminate="columnState.indeterminate"
                        @change="handleColumnAllClick"
                      >
                        {{ t('列展示') }}
                      </a-checkbox>
                      <a @click="reset">{{ t('重置') }}</a>
                    </div>
                  </template>
                  <template #content>
                    <span class="ant-pro-table-column-setting-list">
                      <drag-container
                        lockAxis="y"
                        dragClass="ant-pro-table-drag-ghost"
                        dropClass="ant-pro-table-drop-ghost"
                        @drop="({ removedIndex, addedIndex }) => move(removedIndex, addedIndex)"
                      >
                        <draggable :key="column.key" v-for="column in dynamicColumnItems">
                          <div class="ant-pro-table-column-setting-list-item">
                            <drag-icon />
                            <a-checkbox
                              :checked="column.checked"
                              @change="handleColumnChange($event, column)"
                            >
                              {{ column.label }}
                            </a-checkbox>
                          </div>
                        </draggable>
                      </drag-container>
                    </span>
                  </template>
                  <a-tooltip :title="t('列设置')">
                    <setting-outlined />
                  </a-tooltip>
                </a-popover>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="screenState ? t('退出全屏') : t('全屏')">
                  <fullscreen-outlined v-if="!screenState" @click="setFull" />
                  <fullscreen-exit-outlined v-else @click="exitFull" />
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
        <a-affix :offset-top="top">
          <a-table
            bordered
            :scroll="{ y: tableHeight.y }"
            :row-class-name="stripe"
            :size="state.tableSize"
            :loading="state.loading"
            :columns="dynamicColumns"
            :data-source="state.dataSource"
            :pagination="{
              current: state.current,
              pageSize: state.pageSize,
              total: state.total,
            }"
            @change="handleTableChange"
          >
            <template #index="{ index }">
              <span>{{ index + 1 + state.pageSize * (state.current - 1) }}</span>
            </template>
            <template #status="{ text }">
              <a-tag :color="statusMap[text].status">
                {{ statusMap[text].text }}
              </a-tag>
            </template>
            <template #tongji="{ text }">
              <a-tag v-if="text" color="green">{{ t('是') }}</a-tag>
              <a-tag v-if="!text" color="red">{{ t('否') }}</a-tag>
            </template>
            <template #expandedRowRender="{ record }">
              <div class="table-role-permission-card">
                <a-row :gutter="12">
                  <a-col
                    style="height: 100px"
                    :span="24"
                    :key="permission.id"
                    v-for="permission in record.menu_list"
                  >
                    <a-row :gutter="16" justify="start">
                      <a-col :span="4">{{ permission.full_name }}</a-col>
                      <a-col>
                        <a-tag color="cyan" :key="action" v-for="action in permission.son_list">
                          {{ action.full_name }}
                        </a-tag>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
              </div>
            </template>
            <template #personList="{ record }">
              <a-tag
                color="#87d068"
                v-for="obj in record.person_list"
                :key="obj.id"
                style="margin-bottom: 1px"
              >
                {{ obj.id + '#' + obj.realname }}
              </a-tag>
            </template>
            <template #action="{ record }">
              <a-button
                type="success"
                size="small"
                @click="() => handleAuthEdit(record)"
                v-if="record.is_active"
              >
                {{ t('修改权限') }}
              </a-button>
              <a-button
                type="success"
                size="small"
                @click="() => handlePersonEdit(record)"
                v-if="record.is_active"
              >
                {{ t('修改用户') }}
              </a-button>
              <a-button
                type="primary"
                size="small"
                @click="() => handleOpenEdit(record)"
                v-if="record.is_active"
              >
                {{ t('修改角色') }}
              </a-button>
              <a-popconfirm v-if="record.is_active" @confirm="() => handleDelete(record)">
                <template #title>
                  <p>
                    {{ t('是否确认删除') }}
                    <span style="color: dodgerblue">{{ record.name }}</span>
                    ?
                  </p>
                </template>
                <a-button size="small" type="danger">{{ t('删除') }}</a-button>
              </a-popconfirm>
              <a-button
                type="danger"
                size="small"
                @click="() => handleRecover(record)"
                v-if="!record.is_active"
              >
                {{ t('恢复') }}
              </a-button>
            </template>
          </a-table>
        </a-affix>
      </a-card>
    </div>
    <form-modal
      v-bind="editModal"
      v-if="editModal.visible"
      @cancel="
        () => {
          editModal.visible = false;
        }
      "
      @ok="handlerOk"
    />
    <auth-edit-modal
      v-bind="authEditModal"
      v-if="authEditModal.visible"
      @cancel="
        () => {
          authEditModal.visible = false;
        }
      "
      @ok="
        () => {
          authEditModal.visible = false;
        }
      "
    />
  </page-container3>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from 'vue';
import { Badge, message } from 'ant-design-vue';
import {
  ColumnHeightOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import modalBox from '@/components/form-modal/modal-tools';
import { addRole, queryRoleAndPerson, recoverRole, removeRole, updateRole } from '@/api/permission/auth-manage';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import FormModal from '../../../components/form-modal/form-modal.vue';
import PersonModal from '@/components/person-model/person-model.vue';
import { useStore } from 'vuex';
import { queryOrgList } from '@/api/sys';
import { useForm } from 'ant-design-vue/es/form';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import AuthEditModal from '@/views/permission/auth-manage/autheditmodal/index.vue';
import bus from '@/utils/bus';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'AuthManage',
  setup() {
    const { t } = useI18n();
    const bus_obj = bus;
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const tableHeight = reactive({ y: window.innerHeight - 130 });
    const statusMap = {
      true: { text: t('正常'), status: 'green' },
      false: { text: t('禁用'), status: 'red' },
    };

    const baseColumns: TableColumn[] = [
      {
        title: t('序号'),
        width: 50,
        dataIndex: 'index',
        slots: { customRender: 'index' },
      },
      {
        title: t('名称'),
        width: 150,
        dataIndex: 'name',
      },
      {
        title: t('描述'),
        dataIndex: 'desc',
      },
      {
        title: t('操作'),
        dataIndex: 'action',
        slots: { customRender: 'action' },
      },
    ];
    onMounted(() => {
      window.onresize = function () {
        tableHeight.y = window.innerHeight - 130;
      };
    });
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
    } = useTableDynamicColumns(baseColumns, true);
    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();
    const org_list: any = reactive([]);
    queryOrgList().then(res => {
      res.data.forEach(org => {
        org_list.push(org);
      });
      console.log(org_list);
    });
    const modelRef = reactive({
      org_id: current_org.id,
      aim_org_id: current_org.id,
      is_active: true,
      get_menu: true,
    });
    const config_options = {
      is_active: [
        { name: t('全部'), value: '' },
        { name: t('正常'), value: true },
        { name: t('禁用'), value: false },
      ],
      aim_org_id: org_list,
    };
    const config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'select',
          name: 'aim_org_id',
          label: '目标组织',
          label_i18n: '目标组织',
          placeholder_i18n: '请选择目标组织',
          disabled: false,
          labelKey: 'name',
          valueKey: 'id',
          placeholder: '请填组织id',
          datasourceType: 'remote',
          datasource: 'aim_org_id',
          mode: 'default',
        },
        {
          type: 'select',
          name: 'is_active',
          label: '状态',
          label_i18n: '状态',
          mode: 'default',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择状态',
          datasource: 'is_active',
        },
      ],
      rules: {
        aim_org_id: [{ required: true, message: t('请选择组织') }],
      },
      model: modelRef,
    };
    const { validateInfos } = useForm(modelRef, {});

    const { stripe, reload, setPageInfo, context: state } = useFetchData(queryRoleAndPerson, {
      current: 1,
      pageSize: 20,
      tableSize: 'middle', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...modelRef,
      },
    });
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      setPageInfo({
        current,
        pageSize,
        ...filters,
        ...modelRef,
      });

      reload();
    };

    const search = () => {
      setPageInfo({
        current: 1,
        ...modelRef,
      });
      reload();
    };
    // edit
    const edit_model_config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'horizontal',
      },
      fields: [
        {
          type: 'input',
          name: 'name',
          label: '角色名称',
          label_i18n: '角色名称',
          placeholder_i18n: '请填写角色名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写角色名称',
        },
        {
          type: 'select',
          name: 'aim_org_id', // 传值字段
          label: '目标组织',
          label_i18n: '目标组织',
          placeholder_i18n: '请选择目标组织',
          disabled: false,
          labelKey: 'name', // options数据
          valueKey: 'id',
          placeholder: '请选择组织',
          datasourceType: 'remote',
          datasource: 'aim_org_id', // 接参字段
        },
        {
          type: 'textarea',
          name: 'desc',
          label: '角色描述',
          label_i18n: '角色描述',
          placeholder_i18n: '请填写角色描述',
          disabled: false,
          allowClear: true,
          defaultValue: '',
          placeholder: '请填写角色描述',
        },
      ],
      rules: {
        name: [{ required: true, message: t('请输入角色名称') }],
        aim_org_id: [{ required: true, message: t('请选择隶属组织') }],
      },
      model: {},
    };
    const edit_model_config_options = {
      aim_org_id: org_list,
    };

    const editModal = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: edit_model_config,
      options: edit_model_config_options,
    });
    let currentRecord = reactive({ name: '' });
    let record_model = false;
    const handleOpenEdit = (record: any) => {
      record_model = true;
      editModal.visible = true;
      editModal.title = t('修改角色');
      editModal.form.model = {
        ...record,
        aim_org_id: record.org_id,
      };
      currentRecord = record;
    };
    const handleCreatEdit = () => {
      record_model = false;
      editModal.visible = true;
      editModal.title = t('创建角色');
      editModal.form.model = {};
      editModal.aim_org_id = modelRef.aim_org_id;
    };

    const handleDelete = (record: any) => {
      removeRole({ org_id: current_org.id, role_id: record.id }).then(res => {
        message.success(t('删除角色成功'));
        Object.assign(record, res);
      });
    };
    const handleRecover = (record: any) => {
      recoverRole({ org_id: current_org.id, role_id: record.id }).then(res => {
        message.success(t('恢复角色成功'));
        Object.assign(record, res);
      });
    };

    const handlerOk = (data: any) => {
      if (!record_model) {
        addRole({ org_id: current_org.id, aim_org_id: editModal.aim_org_id, ...data }).then(() => {
          message.success(t('新增角色成功'));
          reload();
        });
      } else {
        updateRole({
          ...data,
          org_id: current_org.id,
          aim_org_id: data.org_id,
          role_id: data.id,
        }).then((res: any) => {
          Object.assign(currentRecord, res);
          message.success(t('修改角色成功'));
        });
      }
      editModal.visible = false;
    };

    const editPersonModal = reactive({
      visible: false,
      title: '',
      org_id: '',
      role_id: '',
      person_list: [],
    });
    const handlePersonEdit = (record: any) => {
      const editModal = reactive({
        visible: true,
        title: t('修改用户角色') + `:${record.name}`,
        org_id: record.org_id,
        person_list: record.person_list,
        role_id: record.id,
        component: 'PersonModal',
        ok: (data: any) => {
          return new Promise(function (resolve) {
            if (data && data.person_ids && data.person_ids.length > 0) {
              data.person_ids = data.person_ids.join(',');
            } else {
              data.person_ids = '';
            }
            updateRole({ org_id: current_org.id, aim_org_id: editModal.org_id, ...data }).then(() => {
              message.success(t('修改用户成功'));
              search();
              resolve(true);
            });
            editModal.visible = false;
          });

        },
      });
      modalBox(editModal);
    };
    const handlerOkperson = (data: any) => {
      if (data && data.person_ids && data.person_ids.length > 0) {
        data.person_ids = data.person_ids.join(',');
      } else {
        data.person_ids = '';
      }
      updateRole({ org_id: current_org.id, aim_org_id: editModal.aim_org_id, ...data }).then(() => {
        message.success('修改用户成功');
        reload();
      });
      editPersonModal.visible = false;
    };
    //
    const authEditModal = reactive({
      visible: false,
      role_id: undefined,
      aim_org_id: undefined,
    });
    const handleAuthEdit = (row: any) => {
      authEditModal.visible = true;
      authEditModal.role_id = row.id;
      authEditModal.aim_org_id = row.org_id;
    };
    bus_obj.on('refresh_auth_manage', () => {
      reload();
    });
    return {
      t,
      statusMap,
      tableHeight,
      state,
      org_list,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,

      handlerOk,
      handlerOkperson,
      currentRecord,
      modelRef,
      validateInfos,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      search,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      current_org,
      message,
      addRole,
      updateRole,
      // removePerson,
      // recoverPerson,
      // edit
      edit_model_config,
      edit_model_config_options,
      editModal,
      editPersonModal,
      handlePersonEdit,
      handleOpenEdit,
      handleCreatEdit,
      handleDelete,
      handleRecover,
      reload,
      config,
      config_options,
      //auth_edit
      authEditModal,
      handleAuthEdit,
    };
  },
  components: {
    AuthEditModal,
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    Draggable,
    DragContainer,
    [Badge.name]: Badge,
    FormModal,
    PersonModal,
    SearchRender,
  },
});
</script>
