
import { defineComponent, onMounted, reactive } from 'vue';
import { Badge, message } from 'ant-design-vue';
import {
  ColumnHeightOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import modalBox from '@/components/form-modal/modal-tools';
import { addRole, queryRoleAndPerson, recoverRole, removeRole, updateRole } from '@/api/permission/auth-manage';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import FormModal from '../../../components/form-modal/form-modal.vue';
import PersonModal from '@/components/person-model/person-model.vue';
import { useStore } from 'vuex';
import { queryOrgList } from '@/api/sys';
import { useForm } from 'ant-design-vue/es/form';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import AuthEditModal from '@/views/permission/auth-manage/autheditmodal/index.vue';
import bus from '@/utils/bus';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'AuthManage',
  setup() {
    const { t } = useI18n();
    const bus_obj = bus;
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const tableHeight = reactive({ y: window.innerHeight - 130 });
    const statusMap = {
      true: { text: t('正常'), status: 'green' },
      false: { text: t('禁用'), status: 'red' },
    };

    const baseColumns: TableColumn[] = [
      {
        title: t('序号'),
        width: 50,
        dataIndex: 'index',
        slots: { customRender: 'index' },
      },
      {
        title: t('名称'),
        width: 150,
        dataIndex: 'name',
      },
      {
        title: t('描述'),
        dataIndex: 'desc',
      },
      {
        title: t('操作'),
        dataIndex: 'action',
        slots: { customRender: 'action' },
      },
    ];
    onMounted(() => {
      window.onresize = function () {
        tableHeight.y = window.innerHeight - 130;
      };
    });
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
    } = useTableDynamicColumns(baseColumns, true);
    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();
    const org_list: any = reactive([]);
    queryOrgList().then(res => {
      res.data.forEach(org => {
        org_list.push(org);
      });
      console.log(org_list);
    });
    const modelRef = reactive({
      org_id: current_org.id,
      aim_org_id: current_org.id,
      is_active: true,
      get_menu: true,
    });
    const config_options = {
      is_active: [
        { name: t('全部'), value: '' },
        { name: t('正常'), value: true },
        { name: t('禁用'), value: false },
      ],
      aim_org_id: org_list,
    };
    const config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'select',
          name: 'aim_org_id',
          label: '目标组织',
          label_i18n: '目标组织',
          placeholder_i18n: '请选择目标组织',
          disabled: false,
          labelKey: 'name',
          valueKey: 'id',
          placeholder: '请填组织id',
          datasourceType: 'remote',
          datasource: 'aim_org_id',
          mode: 'default',
        },
        {
          type: 'select',
          name: 'is_active',
          label: '状态',
          label_i18n: '状态',
          mode: 'default',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择状态',
          datasource: 'is_active',
        },
      ],
      rules: {
        aim_org_id: [{ required: true, message: t('请选择组织') }],
      },
      model: modelRef,
    };
    const { validateInfos } = useForm(modelRef, {});

    const { stripe, reload, setPageInfo, context: state } = useFetchData(queryRoleAndPerson, {
      current: 1,
      pageSize: 20,
      tableSize: 'middle', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...modelRef,
      },
    });
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      setPageInfo({
        current,
        pageSize,
        ...filters,
        ...modelRef,
      });

      reload();
    };

    const search = () => {
      setPageInfo({
        current: 1,
        ...modelRef,
      });
      reload();
    };
    // edit
    const edit_model_config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'horizontal',
      },
      fields: [
        {
          type: 'input',
          name: 'name',
          label: '角色名称',
          label_i18n: '角色名称',
          placeholder_i18n: '请填写角色名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写角色名称',
        },
        {
          type: 'select',
          name: 'aim_org_id', // 传值字段
          label: '目标组织',
          label_i18n: '目标组织',
          placeholder_i18n: '请选择目标组织',
          disabled: false,
          labelKey: 'name', // options数据
          valueKey: 'id',
          placeholder: '请选择组织',
          datasourceType: 'remote',
          datasource: 'aim_org_id', // 接参字段
        },
        {
          type: 'textarea',
          name: 'desc',
          label: '角色描述',
          label_i18n: '角色描述',
          placeholder_i18n: '请填写角色描述',
          disabled: false,
          allowClear: true,
          defaultValue: '',
          placeholder: '请填写角色描述',
        },
      ],
      rules: {
        name: [{ required: true, message: t('请输入角色名称') }],
        aim_org_id: [{ required: true, message: t('请选择隶属组织') }],
      },
      model: {},
    };
    const edit_model_config_options = {
      aim_org_id: org_list,
    };

    const editModal = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: edit_model_config,
      options: edit_model_config_options,
    });
    let currentRecord = reactive({ name: '' });
    let record_model = false;
    const handleOpenEdit = (record: any) => {
      record_model = true;
      editModal.visible = true;
      editModal.title = t('修改角色');
      editModal.form.model = {
        ...record,
        aim_org_id: record.org_id,
      };
      currentRecord = record;
    };
    const handleCreatEdit = () => {
      record_model = false;
      editModal.visible = true;
      editModal.title = t('创建角色');
      editModal.form.model = {};
      editModal.aim_org_id = modelRef.aim_org_id;
    };

    const handleDelete = (record: any) => {
      removeRole({ org_id: current_org.id, role_id: record.id }).then(res => {
        message.success(t('删除角色成功'));
        Object.assign(record, res);
      });
    };
    const handleRecover = (record: any) => {
      recoverRole({ org_id: current_org.id, role_id: record.id }).then(res => {
        message.success(t('恢复角色成功'));
        Object.assign(record, res);
      });
    };

    const handlerOk = (data: any) => {
      if (!record_model) {
        addRole({ org_id: current_org.id, aim_org_id: editModal.aim_org_id, ...data }).then(() => {
          message.success(t('新增角色成功'));
          reload();
        });
      } else {
        updateRole({
          ...data,
          org_id: current_org.id,
          aim_org_id: data.org_id,
          role_id: data.id,
        }).then((res: any) => {
          Object.assign(currentRecord, res);
          message.success(t('修改角色成功'));
        });
      }
      editModal.visible = false;
    };

    const editPersonModal = reactive({
      visible: false,
      title: '',
      org_id: '',
      role_id: '',
      person_list: [],
    });
    const handlePersonEdit = (record: any) => {
      const editModal = reactive({
        visible: true,
        title: t('修改用户角色') + `:${record.name}`,
        org_id: record.org_id,
        person_list: record.person_list,
        role_id: record.id,
        component: 'PersonModal',
        ok: (data: any) => {
          return new Promise(function (resolve) {
            if (data && data.person_ids && data.person_ids.length > 0) {
              data.person_ids = data.person_ids.join(',');
            } else {
              data.person_ids = '';
            }
            updateRole({ org_id: current_org.id, aim_org_id: editModal.org_id, ...data }).then(() => {
              message.success(t('修改用户成功'));
              search();
              resolve(true);
            });
            editModal.visible = false;
          });

        },
      });
      modalBox(editModal);
    };
    const handlerOkperson = (data: any) => {
      if (data && data.person_ids && data.person_ids.length > 0) {
        data.person_ids = data.person_ids.join(',');
      } else {
        data.person_ids = '';
      }
      updateRole({ org_id: current_org.id, aim_org_id: editModal.aim_org_id, ...data }).then(() => {
        message.success('修改用户成功');
        reload();
      });
      editPersonModal.visible = false;
    };
    //
    const authEditModal = reactive({
      visible: false,
      role_id: undefined,
      aim_org_id: undefined,
    });
    const handleAuthEdit = (row: any) => {
      authEditModal.visible = true;
      authEditModal.role_id = row.id;
      authEditModal.aim_org_id = row.org_id;
    };
    bus_obj.on('refresh_auth_manage', () => {
      reload();
    });
    return {
      t,
      statusMap,
      tableHeight,
      state,
      org_list,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,

      handlerOk,
      handlerOkperson,
      currentRecord,
      modelRef,
      validateInfos,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      search,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      current_org,
      message,
      addRole,
      updateRole,
      // removePerson,
      // recoverPerson,
      // edit
      edit_model_config,
      edit_model_config_options,
      editModal,
      editPersonModal,
      handlePersonEdit,
      handleOpenEdit,
      handleCreatEdit,
      handleDelete,
      handleRecover,
      reload,
      config,
      config_options,
      //auth_edit
      authEditModal,
      handleAuthEdit,
    };
  },
  components: {
    AuthEditModal,
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    Draggable,
    DragContainer,
    [Badge.name]: Badge,
    FormModal,
    PersonModal,
    SearchRender,
  },
});
